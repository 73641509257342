<template>
  <div class="loyalty-details">
    <div v-if="nextBonuses[0]" class="loyalty-details__active loyalty-details__row">
      <div class="loyalty-details__active-border-overlay" />
      <div class="loyalty-details__active-bg-overlay" />

      <loyalty-tag v-bind="nextBonuses[0].tagData" />
      <span class="loyalty-details__row-name">{{ nextBonuses[0].name }}</span>
      <span class="loyalty-details__active-label">
        {{ getContent(globalComponentsContent, defaultLocaleGlobalComponentsContent, 'loyalty.nextBonusLabel') }}
      </span>
      <span class="loyalty-details__row-value">{{ nextBonuses[0].bonus }}</span>
    </div>

    <div v-for="(bonus, index) in nextBonuses.slice(1)" :key="index" class="loyalty-details__row">
      <loyalty-tag v-bind="bonus.tagData" />
      <span class="loyalty-details__row-name">{{ bonus.name }}</span>
      <span class="loyalty-details__row-value">{{ bonus.bonus }}</span>
    </div>
  </div>
</template>

<script lang="ts" setup>
  const { getContent } = useProjectMethods();
  const { globalComponentsContent, defaultLocaleGlobalComponentsContent } = useGlobalStore();
  const loyaltyStore = useLoyaltyStore();
  const { loyaltyLevels, loyaltyAccount } = storeToRefs(loyaltyStore);
  const bonusesListContent =
    getContent(globalComponentsContent, defaultLocaleGlobalComponentsContent, 'loyalty.levelUpBonuses') || {};

  const tagsContent = defaultLocaleGlobalComponentsContent
    ? defaultLocaleGlobalComponentsContent.loyalty?.tags
    : globalComponentsContent?.loyalty?.tags || [];

  const nextBonuses = computed(() => {
    if (!loyaltyLevels.value.length || !loyaltyAccount.value) return [];

    const list: {
      name: string;
      tagData: { topColor?: string; bottomColor?: string };
      bonus: string;
    }[] = [];

    loyaltyLevels.value.forEach(levelData => {
      if (levelData.order <= (loyaltyAccount.value?.currentLevel.order ?? 100)) return;

      const tagContentData = tagsContent.find(tag => tag.loyaltyLevels.includes(String(levelData.order)));

      list.push({
        name: levelData.name,
        tagData: { topColor: tagContentData?.topColor, bottomColor: tagContentData?.bottomColor },
        bonus: bonusesListContent[String(levelData.order)]?.value,
      });
    });

    return list;
  });
</script>

<style src="~/assets/styles/components/loyalty/details.scss" lang="scss" />
